@font-face {
font-family: 'localInterFont';
src: url(/_next/static/media/f8c4c2644cc53633-s.p.woff2) format('woff2');
font-display: swap;
font-weight: bold;
font-style: normal;
}

@font-face {
font-family: 'localInterFont';
src: url(/_next/static/media/929a7e516843282c-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 600;
font-style: normal;
}

@font-face {
font-family: 'localInterFont';
src: url(/_next/static/media/0c6c55d5bd465623-s.p.woff2) format('woff2');
font-display: swap;
font-weight: normal;
font-style: normal;
}@font-face {font-family: 'localInterFont Fallback';src: local("Arial");ascent-override: 90.00%;descent-override: 22.43%;line-gap-override: 0.00%;size-adjust: 107.64%
}.__className_e70486 {font-family: 'localInterFont', 'localInterFont Fallback'
}

